import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { NgClass, NgIf } from '@angular/common';
import { StatusBar, StatusBarInfo } from '@capacitor/status-bar';
import { PlatformService } from '../../services/platform.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'update-app-modal',
  templateUrl: './update-app-modal.component.html',
  styleUrls: ['./update-app-modal.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    NgIf,
    TranslateModule,
    NgClass
  ],
})
export class UpdateAppModalComponent implements AfterViewInit, OnDestroy {
  public progress: number = 1;
  public overlays: boolean = false;
  private interval: NodeJS.Timeout | null = null;
  private duration: number = 5000;
  private steps: number = 50;
  private intervalTime: number = this.duration / this.steps;
  private decrement: number = 1 / this.steps;

  constructor(
    public platformService: PlatformService,
    private modalController: ModalController,
  ) {
    if (this.platformService.isAndroidApp) {
      StatusBar.getInfo().then((info: StatusBarInfo) => {
        this.overlays = info.overlays;
      });
    }
  }

  ngAfterViewInit() {
    this.startProgressTimer();
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  private startProgressTimer() {
    this.interval = setInterval(() => {
      this.progress = Math.max(this.progress - this.decrement, 0);

      if (this.progress == 0) {
        this.completeProgress();
      }
    }, this.intervalTime);
  }

  private completeProgress() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  public async downloadApp(): Promise<void> {
    await this.close('download');
  }

  public async close(role: string): Promise<void> {
    await this.modalController?.dismiss(null, role);
  }
}
