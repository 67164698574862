import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PlatformService } from '../../services/platform.service';
import { UpdateAppService } from '../../services/update-app.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'download-app-prompt',
  templateUrl: './download-app-prompt.component.html',
  styleUrls: ['./download-app-prompt.component.scss'],
  imports: [
    NgIf,
    IonicModule,
    TranslateModule
  ],
  standalone: true
})
export class DownloadAppPromptComponent {

  constructor(
    public platformService: PlatformService,
    public updateAppService: UpdateAppService,
  ) {
  }
}