import { Injectable } from '@angular/core';
import { AlertController, AlertOptions, LoadingController, LoadingOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, tap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private countOfLoaders: number = 0;
  private timeoutAllowAbort: number = 10_000; // 10 seconds
  private loadingElement: HTMLIonLoadingElement;
  private timerLoadingAbort: Subscription;
  private alertLoadingAbort: HTMLIonAlertElement;

  constructor(
    private loadingController: LoadingController,
    private translate: TranslateService,
    private alertController: AlertController,
  ) {
  }

  public async start(): Promise<void> {
    if (typeof window === 'undefined') return;

    this.countOfLoaders++;
    if (this.countOfLoaders == 1 && !this.loadingElement) {
      await this.showLoader();

      this.timerLoadingAbort = timer(this.timeoutAllowAbort).pipe(
        tap(() => {
          if (this.loadingElement) {
            this.loadingElement.backdropDismiss = true;
            this.loadingElement.addEventListener('ionBackdropTap', async (): Promise<void> => {
              await this.showAlertLoaderAbort();
            });

            this.loadingElement.addEventListener('click', async (): Promise<void> => {
              await this.showAlertLoaderAbort();
            });
          }
        })
      ).subscribe();
    }
  }

  public async stop(): Promise<void> {
    if (typeof window === 'undefined') return;

    this.countOfLoaders--;
    if (this.countOfLoaders == 0 && this.loadingElement) {
      await this.alertLoadingAbort?.dismiss();
      this.timerLoadingAbort?.unsubscribe();
      await this.loadingElement?.dismiss();
      this.loadingElement = null;
    }
  }

  private async dismissController(): Promise<void> {
    await this.alertLoadingAbort?.dismiss();
    this.timerLoadingAbort?.unsubscribe();
    await this.loadingController?.dismiss();
    this.loadingElement = null;
    this.countOfLoaders = 0;
  }

  private createLoaderElement(): Promise<HTMLIonLoadingElement> {
    const options: LoadingOptions = {
      message: this.translate.instant('loading'), // FIXME: error: "Translation missing for key:  loading"
      backdropDismiss: false,
    };

    return this.loadingController.create(options);
  }

  private async showLoader(): Promise<void> {
    if (!this.loadingElement) {
      this.loadingElement = await this.createLoaderElement();
      await this.loadingElement?.present();
    }
  }

  private createAlertLoaderAbort(): Promise<HTMLIonAlertElement> {
    const opts: AlertOptions = {
      header: this.translate.instant('loadingService.timeoutRequest.alert.header'),
      message: this.translate.instant('loadingService.timeoutRequest.alert.message'),
      cssClass: 'alert-loader-abort',
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('loadingService.timeoutRequest.alert.buttons.cancel'),
          role: 'cancel',
        },
        {
          text: this.translate.instant('loadingService.timeoutRequest.alert.buttons.destructive'),
          role: 'destructive',
          handler: async (): Promise<void> => {
            await this.dismissController();
          }
        }
      ]
    };

    return this.alertController.create(opts);
  }

  private async showAlertLoaderAbort(): Promise<void> {
    if (this.loadingElement) {
      this.alertLoadingAbort = await this.createAlertLoaderAbort();
      await this.alertLoadingAbort.present();
    }
  }
}
