import { Injectable } from '@angular/core';
import { IBody, LoginSmsRequest, SendSmsRequest, } from '../api-clients/pyjam/client';
import { AuthService } from '../auth/auth.service';
import { AppInfoService } from '../services/app-info.service';


@Injectable()
export class LoginController {
  public isInitialised: boolean;
  public login: string;
  public code: string;
  public backUrl: string;
  public isPhone: boolean = false;

  public constructor(
    private authService: AuthService,
    private appInfoService: AppInfoService,
  ) {
    if (typeof window !== 'undefined') {
      this.backUrl = window.sessionStorage.getItem('lastURL');
    }
  }

  public init(): void {
    this.isInitialised = true;
  }

  public clear(): void {
    this.isInitialised = null;
    this.login = null;
    this.code = null;
    this.backUrl = null;
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('introPassed', '1');
      sessionStorage.removeItem('noAuthBtn');
    }
  }

  public changeIsPhone(isPhone: boolean): void {
    this.isPhone = isPhone;
  }

  public pushLogin(login: string): void {
    this.login = login;
  }

  public pushCode(code: string): void {
    this.code = code;
  }

  // public pushBackUrl(backUrl: string) {
  //   this.backUrl = backUrl;
  // }

  public async sendSmsCodeRequest(): Promise<string> {
    if (typeof window === 'undefined') return;

    this.backUrl = window.sessionStorage.getItem('lastURL');
    const request = new SendSmsRequest({
      version: await this.appInfoService.getShortAppVersion(),
      login: this.login,
    });

    return this.authService.loginSendSms(request);
  }

  public async sendLoginRequest(): Promise<void> {
    const request = new LoginSmsRequest({
      login: this.login,
      code: this.code
    });

    return this.authService.loginSms(request);
  }

  public async loginWithToken(type: string, token: string): Promise<void> {
    const data: IBody = {
      type: type,
      token: token,
    };

    return this.authService.login(data);
  }
}
